import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  @media ${device.desktopLG} {
    min-height: 504px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }

  .button {
    width: 100%;
    max-width: 384px;
    font-size: 14px;
    line-height: 17px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${orange.extra};
    border-radius: 8px;
    font-weight: 700;
    color: ${white};
  }
`

export const Title = styled.h2`
  display: block;
  font-family: 'Sora';
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }
  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.span`
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const Link = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: ${orange.extra};
  border-bottom: 2px solid ${orange.extra};

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`
