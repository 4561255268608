import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Title, Description, CardWrapper, Card, CardTitle, CardDescription } from './style'

import dataJson from './data.json'
interface IIndicateJson {
  icon: string;
  title: string;
  description: string;
}

const GanhePorAmigoIndicado = () => {
  const indicateJson: IIndicateJson[] = dataJson.indicate

  return (
    <Section className='py-5 bg-grayscale--100' id='vantagens'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-8 text-md-center mx-auto'>
            <Title as='h3'>Quais são as vantagens do Crédito Consignado Inter?</Title>
            <Description>
              Crédito Consignado Inter é para você simplificar a sua vida com <br /> vantagens que nenhuma outra instituição financeira vai oferecer!
            </Description>
          </div>
        </div>

        <div className='row d-flex'>
          {indicateJson.map((data: IIndicateJson) => (
            <CardWrapper key={data.icon} className='d-flex col-12 col-md-6 col-lg-4'>
              <Card>
                <CardTitle>
                  <div>
                    <OrangeIcon size='MD' color='#FF7A00' icon={data.icon} />
                  </div>
                  <span>{data.title}</span>
                </CardTitle>
                <CardDescription>{data.description}</CardDescription>
              </Card>
            </CardWrapper>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default GanhePorAmigoIndicado
