import React from 'react'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import AlcanceObjetivosJSON from '../../assets/data/alcance-objetiovos.json'

import { ListWrapper, Text, Section, Title, Description } from './style'

import EducationSVG from '../../assets/images/education.svg'
import GiftSVG from '../../assets/images/gift.svg'
import HealthSVG from '../../assets/images/health.svg'
import HomeSVG from '../../assets/images/home.svg'
import ReceiptSVG from '../../assets/images/receipt.svg'
import TravelSVG from '../../assets/images/travel.svg'

type AlcanceObjetivosProps = {
  icon: string;
  text: string;
}

type TypeSvgIcons = {
  [index: string]: string;
}

const AlcanceObjetivos = () => {
  const svgIcons: TypeSvgIcons = {
    credit: HomeSVG,
    health: HealthSVG,
    travel: TravelSVG,
    gift: GiftSVG,
    'aprenda-a-investir': EducationSVG,
    receipt: ReceiptSVG,
  }

  return (
    <Section className='py-5 d-md-flex d-lg-flex position-relative bg-white' id='comoPossoUsar'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-12 mx-auto text-md-center'>
            <Title>Como posso usar o dinheiro do meu Consignado?</Title>
            <Description>
              O Empréstimo Consignado Inter é para realizar seus sonhos e resolver a sua vida, <span className='d-md-block'>usando o valor como preferir ou precisar.</span>
            </Description>
          </div>
        </div>
        <div className='row'>
          {
            AlcanceObjetivosJSON.map((item: AlcanceObjetivosProps) => (
              <ListWrapper key={item.text} className='col-10 col-md-4'>
                <div className='svg-stroke'>
                  <OrangeIcon color='#FF7A00' icon={item.icon} size='MD' className='d-block d-md-none svg-stroke' />
                  <div className='d-none d-md-block'>
                    <img src={svgIcons[item.icon]} width='72' height='72' alt={`${item.icon} icon`} />
                  </div>
                </div>
                <div>
                  <Text>
                    {item.text}
                  </Text>
                </div>
              </ListWrapper>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default AlcanceObjetivos
