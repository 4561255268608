import React from 'react'
import Img from 'gatsby-image'
import LinkTo from 'src/components/LinkTo/LinkTo'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import usePageQuery from '../../pageQuery'

import { Section, Title, Description, Link } from './style'

const AtendimentoPersonalizado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imagesData = usePageQuery()

  return (
    <Section id='oqueEConsignado' className='bg-white d-flex position-relative align-items-center py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-3 mb-md-0'>
            <Img fluid={imagesData.bannerAtendimentoPersonalizado.fluid} alt='Uma mulher usando uma camisa social laranja e sorrindo' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 pr-md-0'>
            <Title>O que é Empréstimo Consignado?</Title>
            <Description>
              O Empréstimo Consignado é a modalidade de crédito que oferece as melhores condições e taxas do mercado para
              servidores públicos, aposentados e pensionistas do INSS.
            </Description>
            <Description>
              No Consignado o valor das <span className='fw-700'>parcelas é descontado diretamente do seu salário</span> ou benefício, respeitando a sua margem consignável – que varia de acordo com o seu convênio, assim como o prazo máximo de pagamento.
            </Description>
            <LinkTo
              to='#hero-section'
              section='dobra_6'
              sectionName='O que é Empréstimo Consignado?'
              elementName='Simule seu Consignado!'
              sendDatalayerEvent={sendDatalayerEvent}
            >
              <Link>Simule seu Consignado!</Link>
            </LinkTo>
            <Description className='my-4'>
              E se você está sem margem no momento, saiba que ainda é possível economizar no seu Consignado ou pegar
              mais dinheiro na mão. Para isso, basta fazer a Portabilidade Inter e escolher entre reduzir o valor das
              suas parcelas ou pegar o seu troco de volta.
            </Description>
            <LinkTo
              to='#hero-section'
              section='dobra_6'
              sectionName='O que é Empréstimo Consignado?'
              elementName='Estou sem margem!'
              sendDatalayerEvent={sendDatalayerEvent}
            >
              <Link>Estou sem margem!</Link>
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AtendimentoPersonalizado
