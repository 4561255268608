import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

type SectionProps = {
  cHeight: number;
}

export const Section = styled.section<SectionProps>`
  background-color: ${white};

  @media ${device.tablet} {
    min-height: 600px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .container {
    background-repeat: no-repeat;
    background-size: auto ${({ cHeight }: SectionProps) => cHeight < 433 ? `calc(${cHeight}px - 12px)` : '421px'};
    background-position: bottom 80px center;

    @media ${device.tablet} {
      background-image: none;
    }

    @media ${device.desktopLG} {
      padding-bottom: 0;
    }

    .row {
      .grid-right {

        @media ${device.desktopLG} {
          padding-right: 0;
        }

        @media ${device.desktopXL} {
          padding-right: 12px;
        }
      }
    }
  }

  .bread-crumb {
    font-family: 'Sora';
    
    span {
      padding-left: 1px;
      font-weight: bold !important;
      color: ${orange.extra} !important;
      opacity: 1;
    }
    a {
      font-weight: 400 !important;
      color:${grayscale[400]};
      opacity: 0.7;
    }
  }
`

export const Image = styled.div`
   padding-top: 20px;
`

export const Alert = styled.div`
  background: #FFF7D9;
  padding-top: 12px;
  padding-bottom: 12px;

  div, button {
    svg {
      display: block;
    }
  }

  button {
    width: auto;
    background: none;
    border: none;
    padding: 0;
    height: auto;

    &:focus {
      outline: none;
    }
  }
`

export const CTAs = styled.div`
  max-width: 360px;
`

export const Simulator = styled.div`
  background-repeat: no-repeat;
  background-position: center 40%;
  background-size: 322px;
  position: relative;

  @media ${device.tablet} {
    background-size: 340px;
  }

  @media ${device.desktopLG} {
    background-size: 376px;
  }

  @media ${device.desktopXL} {
    background-size: 456px;
  }

  .result-simulator {
    font-size: 28px;

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktopXL} {
      font-size: 28px;
    }
  }

  img {
    display: none;

    @media ${device.tablet} {
      position: absolute;
      display: initial;
      width: 500px;
    }

    @media ${device.desktopLG} {
      width: 580px;
    }

    @media ${device.desktopXL} {
      margin-left: 60px;
      padding-right: 46px;
    }

    @media ${device.desktopXXL} {
      margin-left: 60px;
    }

    @media ${device.desktopXXXL} {
      margin-left: 190px;
    }
  }
`
