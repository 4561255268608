import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    background: ${orange.extra};
    border-radius: 8px;
    color: ${white};
    font-weight: 700;
  }
`

export const Title = styled.h2`
  display: block;
  font-family: 'Sora';
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`
