import React, { useState } from 'react'
import Img from 'gatsby-image'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import LinkTo from 'src/components/LinkTo/LinkTo'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import usePageQuery from '../../pageQuery'

import { Section, Indicate, Title } from './style'
import qrCode from '../../assets/images/qrcode-indiqueeganhe.png'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const GanhePorAmigoIndicado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const imagesData = usePageQuery()

  const QRCodeModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Escaneie o QR Code, <span class="d-block"> acesse o app</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí, é só baixar o app do Inter, abrir a sua conta digital e começar a indicar.'
        qrCode={qrCode}
      />
    </Modal>
  )
  return (
    <Section className='py-5 bg-grayscale--100 d-md-flex position-relative align-items-center' id='indiqueEGanhe'>
      {QRCodeModal}
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 order-md-2'>
            <Img fluid={imagesData.ganhePorAmigoIndicadoBanner.fluid} className='' alt='Pessoas sentadas juntas, sorrindo com o smartphone em suas mãos' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-md-1 pr-lg-0'>
            <Title as='h2'>
              Indique e Ganhe R$150 com o Consignado Inter!
            </Title>
            <p className='fs-16 lh-19 fs-lg-17 lh-lg-22 fw-400 text-grayscale--500 mb-4'>
              Indique seus amigos para o Consignado Inter e façam uma renda extra! A cada amigo indicado, cada um de vocês ganha
              <span className='d-inline fw-700'>
                {' '}R$ 150,{' '}
              </span>
              mas o melhor de tudo é: não há limite de indicações.
            </p>
            <Indicate className='d-flex'>
              <div>
                <OrangeIcon size='MD' color='#6A6C72' icon='userdoubt' />
              </div>
              <div className='padding-left'>
                <span className='fs-18 lh-22 fw-700 text-grayscale--500'>Quem pode indicar?</span>
                <p className='fs-16 lh-20 lh-md-19 fw-400 text-grayscale--500 mt-1 mb-4'>
                  Basta ser correntista Inter e você já está pronto para indicar! Ainda não é correntista?
                  <LinkTo
                    to='#hero-section'
                    btncolor='d-block fw-700 text-orange--extra pt-4'
                    section='dobra_12'
                    sectionName='Indique e Ganhe R$150 com o Consignado Inter!'
                    elementName='Abra sua conta!'
                    sendDatalayerEvent={sendDatalayerEvent}
                  > Abra sua conta!
                  </LinkTo>
                </p>
              </div>
            </Indicate>
            <Indicate className='d-flex'>
              <div>
                <OrangeIcon size='MD' color='#6A6C72' icon='users' />
              </div>
              <div className='padding-left'>
                <span className='fs-18 lh-22 fw-700 text-grayscale--500'>Quem pode ser indicado?</span>
                <p className='fs-16 lh-20 lh-md-19 fw-400 text-grayscale--500 mt-1 mb-4'>
                  Todos os servidores públicos, aposentados e pensionistas do INSS de convênios atendidos pelo Inter podem ser indicados para contratar um novo Consignado com a gente.
                  <LinkTo
                    to='#hero-section'
                    btncolor='d-block fw-700 text-orange--extra pt-4'
                    section='dobra_12'
                    sectionName='Indique e Ganhe R$150 com o Consignado Inter!'
                    elementName='Vem indicar!'
                    sendDatalayerEvent={sendDatalayerEvent}
                  > Vem indicar!
                  </LinkTo>
                </p>
              </div>
            </Indicate>
            <a
              href='https://intergo.app/a90523be'
              target='_blank'
              rel='noreferrer'
              title='Saiba como indicar!'
              className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700 d-md-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_12',
                  section_name: 'Indique e Ganhe R$150 com o Consignado Inter!',
                  element_name: 'Saiba como indicar!',
                  element_action: 'click button',
                  redirect_url: 'https://intergo.app/a90523be',
                })
              }}
            >
              Saiba como indicar!
            </a>
            <button
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_12',
                  section_name: 'Indique e Ganhe R$150 com o Consignado Inter!',
                  element_name: 'Saiba como indicar!',
                  element_action: 'click button',
                })
              }}
              className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700 d-none d-md-inline'
            >
              Saiba como indicar!
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GanhePorAmigoIndicado
