import React from 'react'

import Img from 'gatsby-image'

import LinkTo from 'src/components/LinkTo/LinkTo'

import usePageQuery from '../../pageQuery'

import { Section, Title } from './style'
import { DataLayerPropsSection } from 'src/hooks/useDataLayer/types'

const CreditoProtejido = ({ sendDatalayerEvent, section }: DataLayerPropsSection) => {
  const imagesData = usePageQuery()

  return (
    <Section className='py-5 bg-white' id='creditoProtegido'>
      <div className='container py-md-5'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={imagesData.bannerCreditoProtegidoHomem.fluid} alt='Senhor de óculos, camisa cinza azulada sorrindo' />
          </div>
          <div className='col-12 col-md-6 col-xl-6 p-0'>
            <Title>
              Crédito protegido: mais tranquilidade e segurança no seu consignado
            </Title>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Imprevistos acontecem! E com o Crédito Protegido, você garante a sua segurança e a da sua família com a quitação total do seu contrato de Consignado e devolução integral do valor pago, em casos de morte ou invalidez do titular.
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Solicite a contratação do seguro quando for adquirir o seu empréstimo!
            </p>
            <LinkTo
              to='#hero-section'
              btncolor='button mt-4'
              section={section}
              sectionName='Crédito protegido: mais tranquilidade e segurança no seu consignado'
              elementName='Simule o Crédito Consignado!'
              sendDatalayerEvent={sendDatalayerEvent}
            > Simule o Crédito Consignado!
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CreditoProtejido
