import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import ImageBackgroundSM from '../../assets/images/background-portabilidade-consignado-cartao-black-sm.png'
import ImageBackgroundMD from '../../assets/images/background-portabilidade-consignado-cartao-black-md.png'
import ImageBackgroundLG from '../../assets/images/background-portabilidade-consignado-cartao-black-lg.png'
import ImageBackgroundXL from '../../assets/images/background-portabilidade-consignado-cartao-black-xl.png'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  display: flex;
  align-items: center;
  background: ${grayscale[100]} url(${ImageBackgroundSM}) no-repeat;
  background-position: right 24%;
  @media ${device.tablet} {
    background: ${grayscale[100]} url(${ImageBackgroundMD}) no-repeat;
    background-position: 172% center;
  }
  @media ${device.desktopLG} {
    background: ${grayscale[100]} url(${ImageBackgroundLG}) no-repeat;
    background-position: 144% center;
  }
  @media ${device.desktopXL} {
    background: ${grayscale[100]} url(${ImageBackgroundXL}) no-repeat;
    background-position: 114% center;
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopLG} {
      width: 360px;
      margin: 0 auto;
    }
  }
`
