import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import Summary from 'src/components/Summary'

import * as URLs from 'config/api/Urls'

import { Section } from './style'

const Links = [
  {
    text: 'Simulador',
    redirect: true,
    to: `${URLs.API_CONSIGNADO_PRIVADO}/onboarding?utm_source=lpconsignado&utm_medium=botao&utm_campaign=sumario&utm_content=lp`,
  },
  {
    text: 'O que é Consignado?',
    to: '#oqueEConsignado',
  },
  {
    text: 'Consignado é para mim?',
    to: '#consignadoeParaMim',
  },
  {
    text: 'Como posso usar?',
    to: '#comoPossoUsar',
  },
  {
    text: 'Como pedir',
    to: '#pedirConsignado',
  },
  {
    text: 'Vantagens',
    to: '#vantagens',
  },
  {
    text: 'Portabilidade',
    to: '#portabilidade',
  },
  {
    text: 'Crédito protegido',
    to: '#creditoProtegido',
  },
  {
    text: 'Indique e ganhe',
    to: '#indiqueEGanhe',
  },
  {
    text: 'FAQ',
    to: '#faq',
  },
]

const WhatAreYouLookingFor = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Summary
              icon='userdoubt'
              title='O que você procura?'
              links={Links}
              section='dobra_2'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatAreYouLookingFor
