import React from 'react'
import Img from 'gatsby-image'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import * as URLs from '../../../../../../config/api/Urls'

import usePageQuery from '../../pageQuery'

import { Section, ImgSize } from './style'

const Portabilidade = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imagesData = usePageQuery()

  return (
    <Section className='py-5 pt-md-4 py-lg-5 bg-gray' id='portabilidade'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <ImgSize className='col-12 col-md-6 col-xl-5 offset-xl-2 order-md-2'>
            <Img fluid={imagesData.bannerPortabilidade.fluid} className='image' alt='Uma mulher sorrindo com um cartão Inter nas mãos' />
          </ImgSize>
          <div className='col-12 col-md-6 col-xl-5 order-md-1'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 lh-30 lh-md-35 lh-lg-50 text-grayscale--500 fw-600 mt-4 mt-md-0'>
              Faça a Portabilidade de Consignado e reduza suas parcelas!
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 text-grayscale--500 mt-3'>
              Ao fazer a Portabilidade de Consignado para o Inter, você tem liberdade de escolha! Reduza agora o valor das suas parcelas ou receba seu troco de volta e aproveite o Refinanciamento de Consignado, para ter mais dinheiro rápido na mão.
            </p>
            <a
              href={`${URLs.API_CONSIGNADO_NOVO}?origem=site`}
              target='blank' rel='noreferrer'
              className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none mt-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: 'Faça a Portabilidade de Consignado e reduza suas parcelas!',
                  element_name: 'Simule a Portabilidade do Consignado!',
                  element_action: 'click button',
                  redirect_url: `${URLs.API_CONSIGNADO_NOVO}?origem=site`,
                })
              }}
            > Simule a Portabilidade do Consignado!
              <OrangeIcon color='#FFFFFF' icon='arrow-right' size='MD' className='ml-2' />
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Portabilidade
