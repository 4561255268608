import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerMobilePublico: imageSharp(fluid: { originalName: { regex:"/banner-mobile-publico/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerAtendimentoPersonalizado: imageSharp(fluid: { originalName: { regex:"/banner-lp-consignado-atendimento-personalizado/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerPortabilidade: imageSharp(fluid: { originalName: { regex:"/banner-portabilidade-consignado-cartao-black/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerCreditoProtegidoHomem: imageSharp(fluid: { originalName: { regex:"/banner-credito-protegido-homem/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      creditoProtegidoMulher: imageSharp(fluid: { originalName: { regex:"/lp-credito-protegido-mulher/" }}) {
        fluid(maxWidth: 466, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      ganhePorAmigoIndicadoBanner: imageSharp(fluid: { originalName: { regex:"/ganhe-por-amigo-indicado-banner/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      cartaoBlackInvista: imageSharp(fluid: { originalName: { regex:"/cartoes-inter-black-invista/" }}) {
        fluid(maxWidth: 420, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoContratarOConsignadoInter: imageSharp(fluid: { originalName: { regex:"/banner-como-contratar-o-consignado-inter/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerFecheUmContratoDeConsignadoSM: imageSharp(fluid: { originalName: { regex:"/banner-feche-um-contrato-de-consignado-sm/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerFecheUmContratoDeConsignadoMD: imageSharp(fluid: { originalName: { regex:"/banner-feche-um-contrato-de-consignado-md/" }}) {
        fluid(maxWidth: 336, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerFecheUmContratoDeConsignadoLG: imageSharp(fluid: { originalName: { regex:"/banner-feche-um-contrato-de-consignado-lg/" }}) {
        fluid(maxWidth: 376, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerFecheUmContratoDeConsignadoXL: imageSharp(fluid: { originalName: { regex:"/banner-feche-um-contrato-de-consignado-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerCartaoBeneficioINSS: imageSharp(fluid: { originalName: { regex:"/banner-cartao-beneficio-inss/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      orangeFridayMenorTaxa: imageSharp(fluid: { originalName: { regex:"/orange-friday-menor-taxa/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
