import React from 'react'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import TipoDeCreditoJSON from '../../assets/data/tipo-de-credito.json'

import { Section, ColapseList, ColapseListButton, List, Checkbox, Title, Description } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

type TipoDeCreditoProps = {
  id: number;
  setor: string;
  area: string[];
}

const TipoCredito = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section className='pb-5' id='consignadoeParaMim'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-11 col-xl-11 mx-auto text-md-center'>
            <Title as='h2'>O Empréstimo Consignado é para mim? </Title>
            <Description>
              Se você faz parte de algum destes grupos de órgãos públicos e quer simplificar a sua vida,
              <span className='d-lg-block'>
                sem pesar no seu bolso, o Crédito Consignado é para você!
              </span>
            </Description>
          </div>
        </div>
        <div className='row'>
          {
            TipoDeCreditoJSON.map((item: TipoDeCreditoProps) => (
              <div key={item.id.toString()} className='col-12 col-md-4 card-list'>
                <Checkbox id={item.id.toString()} type='checkbox' />
                <ColapseList
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_10',
                    section_name: 'O Empréstimo Consignado é para mim? ',
                    element_name: item.setor,
                    element_action: 'click button',
                  })
                  }}
                >
                  <ColapseListButton htmlFor={item.id.toString()}>
                    <span className='fs-18 lh-22 fs-md-14 lh-md-16 fs-xl-18 lh-xs-2 text-grayscale--500'>{item.setor}</span>
                    <div>
                      <OrangeIcon color='#FF7A00' icon='chevron-down' size='MD' className='icon' />
                    </div>
                  </ColapseListButton>
                  <List>
                    {
                      item.area.map((area: string) => (
                        <li
                          key={area}
                          className='fs-18 lh-22 fs-md-14 lh-md-16 fs-xl-18 lh-xs-2 text-grayscale--500'
                        >
                          {area}
                        </li>
                      ))
                    }
                  </List>
                </ColapseList>
              </div>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default TipoCredito
