import React from 'react'
import { DataLayerPropsSection } from 'src/hooks/useDataLayer/types'

import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import { Alert } from './style'

const MensageAlerta = ({ sendDatalayerEvent, section }: DataLayerPropsSection) => {
  return (
    <section className='bg-white pt-md-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <Alert className='d-md-flex align-items-md-center'>
              <div className='mr-2'>
                <OrangeIcon color='#161616' icon='attention' size='SM' />
              </div>
              <p className='fs-12 lh-14 fw-400 text-grascale--500 m-0'>
                Prezamos pelo seu bem-estar. Se julgar que o contato do Banco Inter para promoções e serviços por
                telefone está invasivo, acesse
                <a
                  href='https://www.naomeperturbe.com.br/'
                  className='fw-700'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: section,
                    section_name: 'Prezamos pelo seu bem-estar.',
                    element_name: 'www.naomeperturbe.com.br',
                    element_action: 'click button',
                    redirect_url: 'https://www.naomeperturbe.com.br/',
                  })
                  }}
                > www.naomeperturbe.com.br
                </a> para bloquear as ligações.
              </p>
            </Alert>
          </div>
          <div className='col-12 mb-4'>
            <p className='fs-14 lh-17 lh-md-14 lh-lg-16 text-grayscale-400'>
              “O Empréstimo Consignado tem prazo mínimo de 24 meses e máximo de 120 meses. Um exemplo desse empréstimo é
              o seguinte: se você pegar um empréstimo de R$ 20.000,00 para pagar em 96 meses com parcelas de R$ 408,17,
              o valor total a pagar seria de R$ 39.184,32, com taxa de juros de 1,54% ao mês. Nessa operação, o Custo
              Efetivo Total (CET) é de 1,57% ao mês (a.m.) e de 20,54% ao ano (a.a.).”
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MensageAlerta
