import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media ${device.desktopXL} {
    min-height: 750px;
    padding-top: 96px!important;
    padding-bottom: 96px!important;
  }
`

export const Title = styled.span`
  display: block;

  font-family: 'Sora';
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};

  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.span`
  display: block;

  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 56px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.desktopXL} {
    padding: 0 22px;
  }
`

export const CardWrapper = styled.div`

& + & {
  margin-top: 24px;

  @media ${device.tablet} {
    margin-top: 0;
  }
}

  @media ${device.tablet} {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      margin-top: 24px;
    }
  }

  @media ${device.desktopLG} {
    &:nth-child(3) {
      margin-top: 0;
    }
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 16px;
  padding: 16px 12px;
  min-height: 123px;


  @media ${device.tablet} {
    min-height: 118px;
  }

  @media ${device.desktopXL} {
    padding: 16px 14px;
  }

  @media ${device.desktopXL} {
    min-height: 129px;
  }
`

export const CardTitle = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;

  svg {
    margin-right: 14px;
  }
  span {
    display: block;

    font-family: 'Sora';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: ${grayscale[500]};

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 20px;
      padding-right: 80px;
    }

    @media ${device.desktopLG} {
      padding-right: 28px;
    }

    @media ${device.desktopXL} {
      font-size: 20px;
      line-height: 25px;
      padding-right: 41px;
    }
  }
`

export const CardDescription = styled.span`
  display: block;

  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  @media ${device.desktopXL} {
    font-size: 18px;
    line-height: 22px;
  }
`
