import React, { useRef } from 'react'

import BreadCrumb from 'src/components/Breadcrumb'
import PayrollSimulator from 'src/components/PayrollSimulator/PayrollSimulatorNew'

import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import { CTAs, Section, Simulator } from './style'

const Hero = ({ sendDatalayerEvent }: DataLayerProps) => {
  const spacingHeight = useRef<HTMLDivElement>(null)
  const customHeight = spacingHeight?.current?.clientWidth || 340

  return (
    <Section
      cHeight={customHeight}
      ref={spacingHeight} id='hero-section'
      className='py-5 d-md-flex position-relative align-items-md-center justify-content-md-center'
    >
      <div className='container pb-xl-5'>
        <div className='row align-items-center align-items-md-center justify-content-between'>
          <div className='col-12 col-md-6 pr-xl-5'>
            <div className='d-none d-md-block mb-md-3'>
              <BreadCrumb
                sectionName='Seu salário e sua margem valem mais no Consignado Inter!'
                type='Pra você'
                text='Empréstimo consignado público'
                link='/pra-voce/emprestimos/emprestimo-consignado-publico/'
              />
            </div>
            <h1 className='fs-24 fs-md-32 fs-lg-40 fs-xl-48 lh-30 lh-md-40 lh-lg-50 lh-xl-60 fw-600 text-grayscale--500'>
              Seu salário e sua margem valem mais no Consignado Inter!
            </h1>
            <p className='fs-18 lh-22 mb-4 mb-md-5 text-grayscale--400'>
              Servidor público, aposentado ou pensionista do INSS, aproveite as melhores condições do mercado e
              contrate o Crédito Consignado sem sair de casa!
            </p>
            <CTAs>
              <a
                href={process.env.ACOMPANHAR_EMPRESTIMO_CONSIGNADO}
                className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mb-5 mb-md-0'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name: 'Seu salário e sua margem valem mais no Consignado Inter!',
                    element_name: 'Já tem uma proposta? Acompanhe.',
                    element_action: 'click button',
                    redirect_url: process.env.ACOMPANHAR_EMPRESTIMO_CONSIGNADO,
                  })
                }} rel='noreferrer'
              >
                Já tem uma proposta? Acompanhe.
              </a>
            </CTAs>
          </div>
          <Simulator className='col-12 col-md-6 col-lg-5 d-flex justify-content-center grid-right px-0'>
            <PayrollSimulator
              page='publico'
              sectionName='Seu salário e sua margem valem mais no Consignado Inter!'
              sectionNumber='dobra_1'
            />
          </Simulator>
        </div>
      </div>
    </Section>
  )
}

export default Hero
